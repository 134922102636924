import React from 'react'
import { graphql } from 'gatsby'
// import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from '../components/Image'

export const ProjectPageTemplate = ({ project }) => {
  return (
    <section className="section">
      <div className="project">
        {project.map((data, index) => (
          <a href={data.url} target="blank" key={index} className="project--wrapper">
            <div className="project--image">
              <Image alt={data.alt} staticImage={data.static} imageInfo={data.image}
                style={{
                  borderRadius: "10px"
                  }}
                  imgStyle={{
                    borderRadius: "10px"
                  }}/>
            </div>
            <div className="project--content">
              <h2>{data.name}</h2>
              <p>{data.description}</p>
            </div>
          </a>
        ))}
      </div>
    </section>
  )
}

const ProjectPage = ({data}) => {
  return (
    <Layout>
      <SEO title="Project" />
      <ProjectPageTemplate
        project={data.markdownRemark.frontmatter.project}
      />
    </Layout>
  )
}

export default ProjectPage

export const projectPageQuery = graphql`
  query GetAllProject {
    markdownRemark(frontmatter: {templateKey: {eq: "project-page"}}) {
    frontmatter {
      title
      templateKey
      project {
        url
        name
        description
        alt
        static
        image {
          childImageSharp {
            fluid(maxWidth: 960, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}`