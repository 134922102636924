import React from 'react'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({ imageInfo, alt, staticImage }) => {
  const { childImageSharp, image } = imageInfo

  if (!!image && !!image.childImageSharp) {
    return (
      <Img fluid={image.childImageSharp.fluid} alt={alt} />
    )
  }

  if (!!childImageSharp) {
    return <Img fluid={childImageSharp.fluid} alt={alt} />
  }

  if (!!image && typeof image === 'string')
    return <img src={staticImage} alt={alt} />

  return <img src={staticImage} alt={alt} />
}

export default PreviewCompatibleImage
